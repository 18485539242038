import React, { useState, useEffect } from 'react';
import fetchData from './ApiPage';
import useMobileCheck from './useMobileCheck';
import BetaBanner from './Component/BetaBanner';

const CompetitionInfoCard = ({ onNavigateToResult, EventID }) => {
  const [competitionData, setCompetitionData] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState('');
  const [inGroupButtons, setInGroupButtons] = useState([]);
  const [selectedInGroupButton, setSelectedInGroupButton] = useState('');
  const [selectedCompetitionSteps, setSelectedCompetitionSteps] = useState([]);
  const [selectedGroupID, setSelectedGroupID] = useState(null);
  const [selectedStageID, setSelectedStageID] = useState(null);
  const [stageStatusDesc, setStageStatusDesc] = useState(null);
  const [stageIsTested, setStageIsTested] = useState(null);
  const [selectedStep, setSelectedStep] = useState(0);
  const [selectedCompetitionID, setSelectedCompetitionID] = useState(null);
  const [selectedClassificationID, setSelectedClassificationID] = useState(() => {
    // Initializing state from localStorage if available
    const savedClassificationID = localStorage.getItem('ClassificationID');
    return savedClassificationID ? parseInt(savedClassificationID, 10) : null;
  });
  const [selectedStageIDStatus, setSelectedStageIDStatus] = useState(null);
  const [isClassificationClicked, setIsClassificationClicked] = useState(false);
  const [filteredClassificationData, setFilteredClassificationData] = useState([]); // New state for filtered classification data
  const [filteredInGroupData, setFilteredInGroupData] = useState([]);
  const [logoClassification, setLogoClassification] = useState([]);
  const [GroupListForCompetition, setGroupListForCompetition] = useState([]);
  const [selectedCompetitionIndex, setSelectedCompetitionIndex] = useState([]);
  const labelStyle = { width: '100px' };
  const isMobile = useMobileCheck();
  

  // Function to extract InGroup data from a single competition object
  const extractInGroupData = (data, classificationID) => {
    const inGroupData = [];
  
    if (Array.isArray(data.Classifications)) {
      // Scenario 1: Full competition object with multiple classifications
      const selectedClassification = data.Classifications.find(
        (classification) => classification.ClassificationID === classificationID
      );
  
      if (selectedClassification && selectedClassification.CompetitionDetails) {
        selectedClassification.CompetitionDetails.InGroup.forEach((group) => {
          const groupValue = Array.isArray(group.InGroupID.GroupsListID)
            ? group.InGroupID.GroupsListID
            : [group.InGroupID.GroupsListID];
  
          inGroupData.push({
            name: group.InGroupName,
            value: groupValue,
          });
        });
      }
    } else if (data.ClassificationID) {
      // Scenario 2: Single classification object
      if (data.ClassificationID === classificationID && data.CompetitionDetails) {
        data.CompetitionDetails.InGroup.forEach((group) => {
          const groupValue = Array.isArray(group.InGroupID.GroupsListID)
            ? group.InGroupID.GroupsListID
            : [group.InGroupID.GroupsListID];
  
          inGroupData.push({
            name: group.InGroupName,
            value: groupValue,
          });
        });
      }
    }
  
    return inGroupData;
  };
  
  useEffect(() => {
    console.log('Attempting WebSocket connection');
    const socket = new WebSocket('wss://www.admin.racepulse.pl:55127'); 
  
    socket.onopen = () => {
      console.log('Connected to WebSocket server');
    };
  
    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
  
        if (data.type === 'NEW_RESULT') {
          console.log('Received NEW_RESULT event:', data);
  
          // Update the current data by calling onNavigateToResult with the relevant parameters
          onNavigateToResult(
            selectedCompetition,
            EventID,
            selectedCompetitionID,
            getFirstGroupID(selectedGroupID),
            selectedStageID,
            stageStatusDesc,
            selectedStageIDStatus,
            selectedClassificationID,
            stageIsTested,
            GroupListForCompetition,
          );
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };
  
    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
  
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    // Clean up the WebSocket connection when the component unmounts
    return () => {
      console.log('Closing WebSocket connection');
      socket.close();
    };
  }, [
    selectedCompetition,
    EventID,
    selectedCompetitionID,
    selectedGroupID,
    selectedStageID,
    stageStatusDesc,
    selectedStageIDStatus,
    selectedClassificationID,
    stageIsTested,
    GroupListForCompetition,
    onNavigateToResult,
  ]);





  const handleStepChange = (e) => {
    const index = Number(e.target.value);
    handleStepClick(index);
  };

  const handleCompetitionChange = (e) => {
    const index = Number(e.target.value);
    handleCompetitionButtonClick(index); // Assuming this function sets the state based on the selected competition
  };

  const handleClassificationChange = (e) => {
    const classificationID = e.target.value;
    handleClassificationButtonClick(classificationID);
  };

  const handleGroupChange = (e) => {
    const groupID = e.target.value;
    setSelectedInGroupButton(groupID);
    localStorage.setItem('mobile_GroupID', groupID); // Save to localStorage
    handleInGroupButtonClick({ InGroupID: { GroupsListID: groupID } });
  };

  useEffect(() => {
    const onDataFetched = (data) => {
      const compIndex = localStorage.getItem('CometitionIndex');
      const classID = localStorage.getItem('ClassificationID');
      const _stageID = localStorage.getItem('StageID');
      const savedGroupID = localStorage.getItem('mobile_GroupID');
      console.log('savedgroup',savedGroupID)
      let firstCompetition;
      let competitionIndex = 0;
      // Determine which competition to use
      if (compIndex == 'null' || compIndex == null ) {
        firstCompetition = data[0];
      
      } else {
        competitionIndex = parseInt(compIndex, 10);
        firstCompetition = data[competitionIndex];
      }
  
      

      let classificationIndex = 0;
      if (classID) {
        classificationIndex = firstCompetition.Classifications.findIndex(
          (classification) => classification.ClassificationID === parseInt(classID, 10)
        );
  
        // If classificationID was found, use its index; otherwise, default to 0.
        if (classificationIndex === -1) classificationIndex = 0;
      }
      setSelectedCompetitionIndex(competitionIndex);
      
      // Get the selected classification
      const firstClassification = firstCompetition.Classifications[classificationIndex];
  
      // Determine stage
      let selectedStage = (firstCompetition.Stages || [])[0];
      let stageIndex = 0;
      if (_stageID) {
        stageIndex = firstCompetition.Stages.findIndex(
          (stage) => stage.StageID === parseInt(_stageID, 10)
        );
  
        if (stageIndex !== -1) {
          selectedStage = firstCompetition.Stages[stageIndex];
        } else {
          stageIndex = 0;
        }
      }
  
      let firstGroup = [];
      let groupList = [];
      let a=[];
  

      if (savedGroupID) {
        // Find the group object that matches savedGroupID
        firstGroup = firstClassification.CompetitionDetails.InGroup.find(
          (group) => group.InGroupID.GroupsListID == savedGroupID
        );

      

        // If not found, default to the first group
        if (!firstGroup) {
          firstGroup = (firstClassification.CompetitionDetails.InGroup || [])[0];
         
        }
       // groupList = extractInGroupData(firstCompetition, firstClassification.ClassificationID);
      } else {
        firstGroup = (firstClassification.CompetitionDetails.InGroup || [])[0];
      
       // groupList = extractInGroupData(firstCompetition, firstClassification.ClassificationID);
      }
      groupList = extractInGroupData(firstCompetition, firstClassification.ClassificationID);
      
      

      


      setSelectedClassificationID(parseInt(classID, 10) || firstClassification.ClassificationID);
      setSelectedInGroupButton(firstGroup ? firstGroup.InGroupID.GroupsListID : null);
      setSelectedGroupID(firstGroup ? firstGroup.InGroupID.GroupsListID : null);
  
      // Set necessary state values
      setCompetitionData(data);
      setGroupListForCompetition(groupList);
      setSelectedCompetition(firstCompetition.NameCompetition);
      setSelectedCompetitionSteps(firstCompetition.Stages || []);
      setInGroupButtons(firstClassification.CompetitionDetails.InGroup || []);
      setSelectedStep(stageIndex);
      setSelectedStageID(selectedStage ? selectedStage.StageID : null);
      setSelectedCompetitionID(firstCompetition.IDCompetition);
      setSelectedStageIDStatus(selectedStage ? selectedStage.StageStatus : null);
      setStageIsTested(selectedStage ? selectedStage.IsTested : null);
      setLogoClassification(firstClassification.LogoClassification);
  
      console.log('group', getFirstGroupID(firstGroup.InGroupID.GroupsListID))
      console.log('groupList', groupList)
      onNavigateToResult(
        firstCompetition.NameCompetition,
        EventID,
        firstCompetition.IDCompetition,
        getFirstGroupID(firstGroup.InGroupID.GroupsListID),
        selectedStage ? selectedStage.StageID : null,
        selectedStage ? selectedStage.StageStatus : null,
        selectedStage ? selectedStage.StageStatusDesc : null,
        firstClassification.ClassificationID,
        selectedStage ? selectedStage.IsTested : null,
        groupList
      );
  
      setFilteredClassificationData(firstCompetition.Classifications || []);
      setFilteredInGroupData(firstClassification.CompetitionDetails.InGroup || []);
    };
  
    fetchData(onDataFetched, EventID);
  }, [EventID]);
  

  //useEffect(() => {
   // if (filteredClassificationData.length > 0) {
  //    const firstClassificationID = filteredClassificationData[0].ClassificationID;
    //  setSelectedClassificationID(firstClassificationID);
  //  }
  //}, [filteredClassificationData]);

  const handleCompetitionButtonClick = (index) => {
    const selectedCompetition = competitionData[index];
  
    const competitionID = selectedCompetition.IDCompetition;
    const competitionName = selectedCompetition.NameCompetition;
    const classificationID = selectedCompetition.Classifications[0].ClassificationID;
    const stages = selectedCompetition.Stages || [];
    const firstStage = stages[0];
    const stageID = firstStage.StageID;
    const stageStatusDesc = firstStage.StageStatusDesc;
    const stageStatus = firstStage.StageStatus;
    const isTested = firstStage.IsTested;
    const firstGroupID = selectedCompetition.Classifications[0].CompetitionDetails.InGroup[0].InGroupID.GroupsListID;
    const groupList = extractInGroupData(selectedCompetition,selectedCompetition.Classifications[0].ClassificationID);
  
    localStorage.setItem('CometitionIndex', index);
    localStorage.setItem('ClassificationID', null);
    // Update states
    setSelectedCompetition(competitionName);
    setSelectedCompetitionID(competitionID);
    setIsClassificationClicked(false);
    setSelectedCompetitionSteps(stages);
    setSelectedStageIDStatus(stageStatus);
    setStageStatusDesc(stageStatusDesc);
    setStageIsTested(isTested);
    setSelectedInGroupButton(firstGroupID);
    setSelectedGroupID(firstGroupID);
    setSelectedStep(0);
    setLogoClassification(selectedCompetition.Classifications[0].LogoClassification);
    setFilteredClassificationData(selectedCompetition.Classifications || []);
    setGroupListForCompetition(groupList)
    setFilteredInGroupData(selectedCompetition.Classifications[0].CompetitionDetails.InGroup || []);


    setSelectedClassificationID(classificationID);
    setSelectedCompetitionIndex(index);

    const inGroupData = selectedCompetition.Classifications[0].CompetitionDetails.InGroup || [];
    setFilteredInGroupData(inGroupData);

  // Handle Group
  let groupID = selectedInGroupButton;
  const groupExists = inGroupData.some(
    (group) => group.InGroupID.GroupsListID === selectedInGroupButton
  );
  if (!groupExists) {
    groupID = inGroupData[0]?.InGroupID?.GroupsListID || '';
  }
  setSelectedInGroupButton(groupID);
  setSelectedGroupID(groupID);
  localStorage.setItem('mobile_GroupID', groupID);

    onNavigateToResult(
      competitionName,
      EventID,
      competitionID,
      firstGroupID, // Use the new value directly
      stageID,
      stageStatus,
      stageStatusDesc,
      classificationID,
      isTested,
      groupList
    );
  };

  const handleInGroupButtonClick = (button) => {
    setSelectedInGroupButton(button.InGroupID.GroupsListID);
    const groupID = getGroupID(button.InGroupID.GroupsListID);
    setSelectedGroupID(getFirstGroupID(button.InGroupID.GroupsListID));
    localStorage.setItem('mobile_GroupID', button.InGroupID.GroupsListID); // Save to localStorage

    setSelectedInGroupButton(getFirstGroupID(button.InGroupID.GroupsListID));

    onNavigateToResult(
      selectedCompetition,
      EventID,
      selectedCompetitionID,
      getFirstGroupID(button.InGroupID.GroupsListID),
      selectedStageID,
      stageStatusDesc,
      selectedStageIDStatus,
      selectedClassificationID,
      stageIsTested,
      getFirstGroupID(button.InGroupID.GroupsListID)
    );
  };

  const handleClassificationButtonClick = (classificationID) => {
    const classificationIDNumber = parseInt(classificationID, 10); // Convert to number
    const selectedClassification = filteredClassificationData.find(
        (cls) => cls.ClassificationID === classificationIDNumber
    );

    if (!selectedClassification) {
        console.error('Selected classification not found');
        return; // Exit if the selected classification is not found
    }

    if (!selectedClassification.CompetitionDetails || !selectedClassification.CompetitionDetails.InGroup) {
        console.error('CompetitionDetails or InGroup is undefined');
        return; // Exit if the CompetitionDetails or InGroup is undefined
    }
    const groupList = extractInGroupData(selectedClassification,classificationIDNumber);
    
    localStorage.setItem('ClassificationID', classificationIDNumber);
    setIsClassificationClicked(true);
    setSelectedClassificationID(classificationIDNumber);
    setSelectedInGroupButton(selectedClassification.CompetitionDetails.InGroup?.[0]?.InGroupID?.GroupsListID);
    setFilteredInGroupData(selectedClassification.CompetitionDetails.InGroup || []);

    onNavigateToResult(
        selectedCompetition,
        EventID,
        selectedCompetitionID,
        selectedClassification.CompetitionDetails.InGroup[0].InGroupID.GroupsListID,
        selectedStageID,
        stageStatusDesc,
        selectedStageIDStatus,
        classificationIDNumber,
        stageIsTested,
        groupList
    );
};


  const getGroupID = (button) => {
    const filteredGroups = inGroupButtons
      .filter((btn) => btn.Group === button)
      .map((btn) => btn.InGroupID?.GroupsListID);
    return filteredGroups;
  };

  const getFirstGroupID = (GroupList) => {
    return [GroupList];
  };

  const handleStepClick = (index) => {
    setSelectedStep(index);
    const stageID = selectedCompetitionSteps[index].StageID;
    const stageStatus = selectedCompetitionSteps[index].StageStatus;
    const stageStatusDesc = selectedCompetitionSteps[index].StageStatusDesc;
    const isTested = selectedCompetitionSteps[index].IsTested;
    setSelectedStageID(stageID);
    setSelectedStageIDStatus(stageID.StageStatus);
    setStageIsTested(isTested);
    localStorage.setItem('StageID', stageID);
    onNavigateToResult(
      selectedCompetition,
      EventID,
      selectedCompetitionID,
      getFirstGroupID(selectedGroupID),
      stageID,
      stageStatus,
      stageStatusDesc,
      selectedClassificationID,
      isTested,
      GroupListForCompetition,
    );
  };

  const getStepClass = (index, selectedStep, step) => {
    const baseClass = 'steps-marker ';
    const isActiveClass = index === selectedStep ? 'is-active is-pulsating' : '';
    const isActive = index === selectedStep ? true : false;
    const pulsatingClass = isActive === false && step.StageStatus === 0 ? '' : '';
    const isBlocked = step.StageStatus === 1 && isActive === false ? 'is-hollow' : '';

    return `${baseClass} ${isActiveClass} ${pulsatingClass} ${isBlocked}`;
  };
  

  return (
    <div className={`container-header ${isMobile ? 'columns is-multiline competition-card is-centered' : ''}`}>
      {!isMobile ? (
        <div className="header">
          <div className="competition-section">
            <div className="competition-logo">
              <img src={logoClassification} alt="Placeholder image" className="logo-image" />
            </div>
            <div className="competition-buttons">
              {competitionData.map((competition, index) => (
                <div
                  key={index}
                  className={`competition-button ${
                    competition.NameCompetition === selectedCompetition ? 'selected' : ''
                  }`}
                  onClick={() => handleCompetitionButtonClick(index)}
                >
                  <div className="competition-name">{competition.NameCompetition}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="classification-buttons">
            {filteredClassificationData.map((classification, index) => (
              <div
                key={index}
                className={`classification-button ${
                  classification.ClassificationID === selectedClassificationID ? 'selected' : ''
                }`}
                onClick={() => handleClassificationButtonClick(classification.ClassificationID)}
              >
                <div className="classification-name">{classification.ClassificationName}</div>
              </div>
            ))}
          </div>
        </div>
      ) :null}
      

  
      {/* Main Content */}
      <div className={`content ${isMobile ? 'column' : ''}`}>
        {isMobile ? (
          <div className="column is-full">
            {/* Dropdown for Competition Selection */}
            <div className="field is-grouped">
              <div className="control is-flex is-align-items-center">
                <label className="label mr-3" style={labelStyle}>
                  Zawody
                </label>
              </div>
              <div className="control is-expanded">
                <div className="select is-fullwidth select-width-consistent">
                <select onChange={handleCompetitionChange} value={selectedCompetitionIndex}>
  {competitionData.map((competition, index) => (
    <option key={index} value={index}>
      {competition.NameCompetition}
    </option>
  ))}
</select>
                </div>
              </div>
            </div>
  
            {/* Dropdown for Classification Selection */}
            <div className="field is-grouped">
              <div className="control is-flex is-align-items-center">
                <label className="label mr-3" style={labelStyle}>
                  Klasyfikacja
                </label>
              </div>
              <div className="control is-expanded">
                <div className="select is-fullwidth select-width-consistent">
                <select onChange={handleClassificationChange} value={selectedClassificationID}>
  {filteredClassificationData.map((classification, index) => (
    <option key={index} value={classification.ClassificationID}>
      {classification.ClassificationName}
    </option>
  ))}
</select>
                </div>
              </div>
            </div>
  
            {/* Dropdown for Group Selection */}
            <div className="field is-grouped">
              <div className="control is-flex is-align-items-center">
                <label className="label mr-3" style={labelStyle}>
                  Klasy
                </label>
              </div>
              <div className="control is-expanded">
                <div className="select is-fullwidth select-width-consistent">
                <select onChange={handleGroupChange} value={selectedInGroupButton}>
  {filteredInGroupData.map((group, index) => (
    <option key={index} value={group.InGroupID.GroupsListID}>
      {group.InGroupName}
    </option>
  ))}
</select>
                </div>
              </div>
            </div>
  
            {/* Dropdown for Step Selection */}
            <div className="field is-grouped">
              <div className="control is-flex is-align-items-center">
                <label className="label mr-3" style={labelStyle}>
                  Odcinki
                </label>
              </div>
              <div className="control is-expanded">
                <div className="select is-fullwidth select-width-consistent">
                <select onChange={handleStepChange} value={selectedStep}>
  {selectedCompetitionSteps.map((step, index) => (
    <option key={index} value={index}>
      {step.StageType}
    </option>
  ))}
</select>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Location Info for Desktop */}
            <div className="location-info">
              <div className="location-name">
                {selectedStep >= 0 && selectedStep < selectedCompetitionSteps.length
                  ? selectedCompetitionSteps[selectedStep].StageDetails
                  : 'Wybierz odcinek'}
              </div>
            </div>
  
            {/* Steps Navigation for Desktop */}
            <div className="column">
              <ul className="steps is-small has-content-centered">
                {selectedCompetitionSteps.map((step, index) => (
                  <li
                    key={index}
                    className={`steps-segment ${index === selectedStep ? 'is-active' : ''}`}
                    onClick={() => handleStepClick(index)}
                  >
                    <span className="steps-marker"></span>
                    <div className="steps-content">
                      <p className={`step-label ${index === selectedStep ? 'is-active-label' : ''}`}>{step.StageType}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CompetitionInfoCard;
