import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Home from './Home';
import About from './About';
import Contact from './Contact';
import MainNavbar from './Navbar';
import Results from './Results';
import ApiPage from './ApiPage';
import PagePenalties from './Penalties';
import Header from './Component/Helmet';
import './custom-styles.scss';

// Your GA4 Measurement ID
const TRACKING_ID = 'G-C06ZJTKQQD';
ReactGA.initialize(TRACKING_ID);

// Component to track page views
const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Send a pageview event on route change
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null;
};

const App = () => {
  return (
    <Router>
      {/* Analytics Tracker Component */}
      <AnalyticsTracker />
      <div>
        <Header />
        <Routes>
          <Route path="/about" element={<MainNavbar showAllMenu={false} />} />
          <Route path="/penalties/*" element={<MainNavbar showAllMenu={true} />} />
          <Route path="/api" element={<MainNavbar showAllMenu={true} />} />
        </Routes>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/penalties/:eventID" element={<PagePenalties />} />
          <Route path="/results/:EventID" element={<Results />} />
          <Route path="/" element={<Home />} />
          <Route path="/api" element={<ApiPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
